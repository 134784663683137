<template>
	<div class="modal">
		<div class="modal-body">
			<div class="modal-text">{{ modalText }}</div>
			<button class="btn" @click="hideModal">Close</button>
		</div>
	</div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    export default {
        name: "Index",
	    computed:{
            ...mapState([
                'modalText'
            ])
	    },
	    methods:{
            ...mapMutations([
                'hideModal'
            ])
	    }
    }
</script>

<style scoped lang="scss">
	.modal{
		position: absolute;
		z-index: 100;
		left: 0;
		top: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0,0,0, .6);
		display: flex;
		justify-content: center;
		align-items: center;
    color: var(--color-modal-text);
		.modal-body{
			max-width: 400px;
			background-color: #fff;
			padding: 20px;
			.btn{
				margin-top: 10px;
				border: 1px solid #6e6e6e;
				padding: 10px 20px;
				color: #6e6e6e;
				background-color: #fff;
				cursor: pointer;
			}
		}
	}
</style>
